const songs = [{
                            songSrc:"https://cdn.protidhwani.tk/song/01 - Bas Ek Baar (128 Kbps) - DownloadMing.LA.mp3",
                            title: "Bas Ek Baar (128 Kbps) -  DownloadMing.SE",
                            artist: "Soham Naik",
                            imgSrc: "https://light.protidhwani.tk/img/BasEkBaar128KbpsDownloadMing.SE.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/01 - Maate Vinadhuga.mp3",
                            title: "Maate Vinadhuga [TeluguSongs.in]",
                            artist: "Sid Sriram",
                            imgSrc: "https://light.protidhwani.tk/img/MaateVinadhugaTeluguSongs.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/01 - Odhani - Made in China (2019).mp3",
                            title: "Odhani - PagalSongs.com",
                            artist: "Neha Kakkar, Darshan Raval",
                            imgSrc: "https://light.protidhwani.tk/img/OdhaniPagalSongs.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/01 Hamari Adhuri Kahani (Title Song) Arijit Singh 320Kbps.mp3",
                            title: "Hamari Adhuri Kahani (Title Song) - PagalWorld.com",
                            artist: "Arijit Singh - PagalWorld.com",
                            imgSrc: "https://light.protidhwani.tk/img/HamariAdhuriKahaniTitleSongPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/01 Sanu Ek Pal Chain - Raid 320Kbps.mp3",
                            title: "Sanu Ek Pal Chain (PagalWorld.info)",
                            artist: "Rahat Fateh Ali Khan (PagalWorld.info)",
                            imgSrc: "https://light.protidhwani.tk/img/SanuEkPalChainPagalWorld.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/01 Tareefan - Veere Di Wedding - Badshah 320Kbps.mp3",
                            title: "Tareefan (PagalWorld.co)",
                            artist: "Badshah , Qaran (PagalWorld.co)",
                            imgSrc: "https://light.protidhwani.tk/img/TareefanPagalWorld.co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/01- Samajavaragamana [NaaSongs.World].mp3",
                            title: "Samajavaragamana [NaaSongs.World]",
                            artist: "[NaaSongs.World]",
                            imgSrc: "https://light.protidhwani.tk/img/SamajavaragamanaNaaSongs.World.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/01. Saibo.mp3",
                            title: "01. Saibo",
                            artist: "Shreya Ghoshal, Tochi Raina",
                            imgSrc: "https://light.protidhwani.tk/img/01.Saibo.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/01_-_Maya(Asomi.In).mp3",
                            title: "Maya-(Asomi.in)",
                            artist: "Zubeen Garg",
                            imgSrc: "https://light.protidhwani.tk/img/MayaAsomi.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/02 - Ramuloo Ramulaa [NaaSongs.World].mp3",
                            title: "02 - Ramuloo Ramulaa [NaaSongs.World]",
                            artist: "Anurag Kulkarni, Mangli Satyavathi [8801436611]",
                            imgSrc: "https://light.protidhwani.tk/img/02RamulooRamulaaNaaSongs.World.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/02 Aashiq Tera - Happy Bhag Jayegi - 320Kbps.mp3",
                            title: "Aashiq Tera - PagalWorld.com",
                            artist: "Sohail Sen , Altamash Faridi - PagalWorld.com",
                            imgSrc: "https://light.protidhwani.tk/img/AashiqTeraPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/02 Jag Ghoomeya - Sultan (Rahat Fateh Ali Khan) 190Kbps.mp3",
                            title: "Jag Ghoomeya (PagalWorld.info)",
                            artist: "Rahat Fateh Ali Khan (PagalWorld.info)",
                            imgSrc: "https://light.protidhwani.tk/img/JagGhoomeyaPagalWorld.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/02 Meer E Kaarwan - Lucknow Central 190Kbps.mp3",
                            title: "Meer E Kaarwan - PagalWorld.cool",
                            artist: "Amit Mishra, Neeti Mohan - PagalWorld.me",
                            imgSrc: "https://light.protidhwani.tk/img/MeerEKaarwanPagalWorld.cool.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/02 Suraiya.mp3",
                            title: "Suraiyya (Pagalworld.org)",
                            artist: "Vishal Dadlani, Shreya Ghoshal (Pagalworld.org)",
                            imgSrc: "https://light.protidhwani.tk/img/SuraiyyaPagalworld.org.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/03 Buddhu Sa Mann - Armaan Malik - 320Kbps.mp3",
                            title: "Buddhu Sa Mann - PagalWorld.cool",
                            artist: "Amaal Mallik , Armaan Malik (PagalWorld.cool)",
                            imgSrc: "https://light.protidhwani.tk/img/BuddhuSaMannPagalWorld.cool.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/03 Ishq Da Sutta.mp3",
                            title: "03 Ishq Da Sutta SongsMp3.Com",
                            artist: "Jasmine Sandlas & Meet Bros",
                            imgSrc: "https://light.protidhwani.tk/img/03IshqDaSuttaSongsMp3.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/03 Tera Rastaa Chhodoon Na - Chennai Express.mp3",
                            title: "Tera Rastaa Chhodoon Na - PagalWorld.info",
                            artist: "Amitabh Bhattacharya & Anusha Mani",
                            imgSrc: "https://light.protidhwani.tk/img/TeraRastaaChhodoonNaPagalWorld.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/04 - Vachindamma [NaaSong.Org].mp3",
                            title: "04 - Vachindamma [NaaSong.Org]",
                            artist: "Sid Sriram",
                            imgSrc: "https://light.protidhwani.tk/img/04VachindammaNaaSong.Org.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/04 Jhuk Na Paunga - Raid 320Kbps.mp3",
                            title: "Jhuk Na Paunga (PagalWorld.info)",
                            artist: "Papon (PagalWorld.info)",
                            imgSrc: "https://light.protidhwani.tk/img/JhukNaPaungaPagalWorld.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/04 Judaai - Badlapur [Arijit Singh] 190Kbps.mp3",
                            title: "Judaai - PagalWorld.com",
                            artist: "Rekha Bhardwaj & Arijit Singh - PagalWorld.com",
                            imgSrc: "https://light.protidhwani.tk/img/JudaaiPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/04 Khoya Khoya (Hero) Nikhil DSouza 320Kbps.mp3",
                            title: "Khoya Khoya [PagalWorld.com]",
                            artist: "Nikhil D'Souza, Priya Panchal, Arpita Chakroborthy & Tanishkaa [PagalWorld.com]",
                            imgSrc: "https://light.protidhwani.tk/img/KhoyaKhoyaPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/04 Nainowale Ne - Padmavati (Neeti) 320Kbps.mp3",
                            title: "Nainowale Ne - PagalWorld.info",
                            artist: "Neeti Mohan - PagalWorld.info",
                            imgSrc: "https://light.protidhwani.tk/img/NainowaleNePagalWorld.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/04 Rangdaari - Lucknow Central (Arijit Singh) 190Kbps.mp3",
                            title: "Rangdaari - PagalWorld.com",
                            artist: "Arijit Singh - PagalWorld.cool",
                            imgSrc: "https://light.protidhwani.tk/img/RangdaariPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/04 Tu Hai (Mohenjo Daro).mp3",
                            title: "Tu Hai - SongsMp3.Info",
                            artist: "A. R. Rahman, Sanah Moidutty",
                            imgSrc: "https://light.protidhwani.tk/img/TuHaiSongsMp3.Info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/04 Zara Si Dosti - Happy Bhag Jayegi (Arijit Singh) 320Kbps.mp3",
                            title: "Zara Si Dosti - PagalWorld.com",
                            artist: "Arijit Singh - PagalWorld.com",
                            imgSrc: "https://light.protidhwani.tk/img/ZaraSiDostiPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/05 - Aanandam Reprise  NaaSong.in .mp3",
                            title: "05 - Aanandam Reprise [NaaSong.in]",
                            artist: "T.S Sarath Santhosh, Shashaa Tirupati",
                            imgSrc: "https://light.protidhwani.tk/img/05AanandamRepriseNaaSong.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/05 Chaandaniya - 2 States [PagalWorld.com].mp3",
                            title: "Chaandaniya - PagalWorld.com",
                            artist: "K Mohan & Yashita Sharma",
                            imgSrc: "https://light.protidhwani.tk/img/ChaandaniyaPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/05 Laagi Na Choote - A Gentleman (Arijit) 190Kbps.mp3",
                            title: "Laagi Na Choote - PagalWorld.cool",
                            artist: "Arijit Singh, Shreya Ghoshal - PagalWorld.me",
                            imgSrc: "https://light.protidhwani.tk/img/LaagiNaChootePagalWorld.cool.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/06 - Yeh Kisne Jadu Kiya - Falguni Pathak - 190Kbps.mp3",
                            title: "Yeh Kisne Jadu Kiya - PagalWorld.com",
                            artist: "Falguni Pathak - PagalWorld.com",
                            imgSrc: "https://light.protidhwani.tk/img/YehKisneJaduKiyaPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/06 Ambarsariya (Fukrey).mp3",
                            title: "Ambarsariya - PagalWorld.com",
                            artist: "Sona Mohapatra - PagalWorld.com",
                            imgSrc: "https://light.protidhwani.tk/img/AmbarsariyaPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/07. Zara Zara.mp3",
                            title: "07. Zara Zara",
                            artist: "Bombay S Jayashri, K K, Kavitha Krishna Murthy, Kk, Pinky, P",
                            imgSrc: "https://light.protidhwani.tk/img/07.ZaraZara.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/11 - Jhoom (R&B Mix) [www.PagalWorld.Com].mp3",
                            title: "11 - Jhoom (R&B Mix) [www.PagalWorld.Com] pagalworld.com",
                            artist: "pagalworld.com",
                            imgSrc: "https://light.protidhwani.tk/img/11JhoomRBMixwww.PagalWorld.Compagalworld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/4 Jhalla Wallah.mp3",
                            title: "4 Jhalla Wallah desifast.in",
                            artist: "desifast.in",
                            imgSrc: "https://light.protidhwani.tk/img/4JhallaWallahdesifast.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/A Poem For You - Isaimini.Audio.mp3",
                            title: "A Poem For You - Isaimini.Audio",
                            artist: "G.V Prakash Kumar - Isaimini.Audio",
                            imgSrc: "https://light.protidhwani.tk/img/APoemForYouIsaimini.Audio.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aag Lage Chahe Basti Mai _ OFFICIAL VIDEO _ SIRAZE(MP3_160K).mp3",
                            title: "Aag Lage Chahe Basti Mai | OFFICIAL VIDEO | SIRAZE",
                            artist: "Sirazee",
                            imgSrc: "https://light.protidhwani.tk/img/AagLageChaheBastiMaiOFFICIALVIDEOSIRAZE.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aaj Bhi - Vishal Mishra 320 Kbps.mp3",
                            title: "Aaj Bhi - Vishal Mishra",
                            artist: "Vishal Mishra",
                            imgSrc: "https://light.protidhwani.tk/img/AajBhiVishalMishra.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aakasam Lona-Naasongs.me.mp3",
                            title: "Aakasam Lona-Naasongs.me",
                            artist: "Nutana Mohan-Naasongs.me",
                            imgSrc: "https://light.protidhwani.tk/img/AakasamLonaNaasongs.me.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/AalaPoraan Tamizhan [Starmusiq.info].MP3",
                            title: "AalaPoraan Tamizhan [Starmusiq.info]",
                            artist: "Kailash Kher, Sathya Prakash, Deepak, Pooja AV",
                            imgSrc: "https://light.protidhwani.tk/img/AalaPoraanTamizhanStarmusiq.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aalolam.mp3",
                            title: "Aalolam",
                            artist: "Shaan Rahman,K. S. Harisankar,Gowry Lekshmi",
                            imgSrc: "https://light.protidhwani.tk/img/Aalolam.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aaoge Jab Tum-Jag Soona Soona.mp3",
                            title: "Aaoge Jab Tum-Jag Soona Soona (Pagalworld.Live)",
                            artist: "Nandini Srikar , Akhil Sachdeva (Pagalworld.Live)",
                            imgSrc: "https://light.protidhwani.tk/img/AaogeJabTumJagSoonaSoonaPagalworld.Live.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aaraadhike - From Ambili.mp3",
                            title: "Aaraadhike - From Ambili",
                            artist: "Vishnu Vijay,Sooraj Santhosh,Madhuvanthi Narayan",
                            imgSrc: "https://light.protidhwani.tk/img/AaraadhikeFromAmbili.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aararum.mp3",
                            title: "Aararum",
                            artist: "K. S. Harisankar,Sam Simon George",
                            imgSrc: "https://light.protidhwani.tk/img/Aararum.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Adhigaalai Pookal - Isaimini.Audio.mp3",
                            title: "Adhigaalai Pookal - Isaimini.Audio",
                            artist: "G.V Prakash Kumar - Isaimini.Audio",
                            imgSrc: "https://light.protidhwani.tk/img/AdhigaalaiPookalIsaimini.Audio.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Adiye.mp3",
                            title: "Adiye",
                            artist: "Dhibu Ninan Thomas,Kapil Kapilan",
                            imgSrc: "https://light.protidhwani.tk/img/Adiye.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aga Naga (From “Ponniyin Selvan Part-2”).mp3",
                            title: "Aga Naga (From “Ponniyin Selvan Part-2”)",
                            artist: "A.R. Rahman,Shakthisree Gopalan",
                            imgSrc: "https://light.protidhwani.tk/img/AgaNagaFromPonniyinSelvanPart2.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Agar Tum Mil Jao - Zeher 320Kbps.mp3",
                            title: "Agar Tum Mil Jao - PagalWorld.info",
                            artist: "Udit Narayan - PagalWorld.info",
                            imgSrc: "https://light.protidhwani.tk/img/AgarTumMilJaoPagalWorld.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ainvayi Ainvayi (PagalWorld.com).mp3",
                            title: "Ainvayi Ainvayi (PagalWorld.com) pagalworld.com",
                            artist: "pagalworld.com",
                            imgSrc: "https://light.protidhwani.tk/img/AinvayiAinvayiPagalWorld.compagalworld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aisa Kyun Hota Hai 128 Kbps.mp3",
                            title: "Aisa Kyun Hota Hai",
                            artist: "Alka Yagnik",
                            imgSrc: "https://light.protidhwani.tk/img/AisaKyunHotaHai.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aiyyashian_Aman_Trikha_781cf1680d4aa839fca920c454ff306a_128.mp3",
                            title: "Aiyyashian RaagMp3.com",
                            artist: "Aman Trikha",
                            imgSrc: "https://light.protidhwani.tk/img/AiyyashianRaagMp3.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Alare.mp3",
                            title: "Alare",
                            artist: "Kailas,Ayraan,Nithya Mammen",
                            imgSrc: "https://light.protidhwani.tk/img/Alare.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Alfaazo.mp3",
                            title: "Alfaazo",
                            artist: "Mitraz",
                            imgSrc: "https://light.protidhwani.tk/img/Alfaazo.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Anaganaganaga - SenSongsMp3.Co.mp3",
                            title: "Anaganaganaga :: SenSongsMp3.Co",
                            artist: "Armaan Malik",
                            imgSrc: "https://light.protidhwani.tk/img/AnaganaganagaSenSongsMp3.Co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Anicham Poovazhagi - Isaimini.Audio.mp3",
                            title: "Anicham Poovazhagi - Isaimini.Audio",
                            artist: "G.V Prakash,Chinna Ponnu & Velmurugan - Isaimini.Audio",
                            imgSrc: "https://light.protidhwani.tk/img/AnichamPoovazhagiIsaimini.Audio.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Anicham_Poovazhagi-VmusiQ.Com.mp3",
                            title: "Anicham Poovazhagi-VmusiQ.Com",
                            artist: "G.V Prakash,Chinna Ponnu & Velmurugan",
                            imgSrc: "https://light.protidhwani.tk/img/AnichamPoovazhagiVmusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Anuraghathin Velayil.mp3",
                            title: "Anuraghathin Velayil",
                            artist: "Vineeth Sreenivasan",
                            imgSrc: "https://light.protidhwani.tk/img/AnuraghathinVelayil.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Arabic Kuthu Beast 320 Kbps.mp3",
                            title: "Arabic Kuthu - PagalNew",
                            artist: "Anirudh Ravichander, Jonita Gandhi",
                            imgSrc: "https://light.protidhwani.tk/img/ArabicKuthuPagalNew.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aradhya (From Kushi) (Telugu).mp3",
                            title: "Aradhya (From Kushi) (Telugu)",
                            artist: "Hesham Abdul Wahab,Chinmayi",
                            imgSrc: "https://light.protidhwani.tk/img/AradhyaFromKushiTelugu.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Athmavin Akashathil - From Njan Prakashan.mp3",
                            title: "Athmavin Akashathil - From Njan Prakashan",
                            artist: "Shaan Rahman,Gowry Lekshmi",
                            imgSrc: "https://light.protidhwani.tk/img/AthmavinAkashathilFromNjanPrakashan.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Aval.mp3",
                            title: "Aval",
                            artist: "Santhosh Narayanan,Pradeep,Priya Hemesh",
                            imgSrc: "https://light.protidhwani.tk/img/Aval.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ay Hairathe.mp3",
                            title: "Ay Hairathe",
                            artist: "A.R. Rahman,Hariharan,Alka Yagnik,Mohammed Aslam",
                            imgSrc: "https://light.protidhwani.tk/img/AyHairathe.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Azaadi Ke Liye (The Forgotten Army) Arijit Singh 320 Kbps(PagalWorldCom.Com).mp3",
                            title: "Azaadi Ke Liye (The Forgotten Army) Arijit Singh 320 Kbps(PagalWorldCom.Com)",
                            artist: "PagalWorldCom.Com",
                            imgSrc: "https://light.protidhwani.tk/img/AzaadiKeLiyeTheForgottenArmyArijitSingh320KbpsPagalWorldCom.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Badan Pe Sitare Lapete Huye (From Prince).mp3",
                            title: "Badan Pe Sitare Lapete Huye (From Prince)",
                            artist: "Mohammed Rafi",
                            imgSrc: "https://light.protidhwani.tk/img/BadanPeSitareLapeteHuyeFromPrince.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Bas-Kar-Ji-MassTamilan.fm.mp3",
                            title: "Bas Kar Ji - MassTamilan.fm",
                            artist: "Hesham Abdul Wahab, Sachin Warrier - MassTamilan.fm",
                            imgSrc: "https://light.protidhwani.tk/img/BasKarJiMassTamilan.fm.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Beh Chala - URI.mp3",
                            title: "Beh Chala - Pagalworld.io",
                            artist: "Yasser Desai (Pagalworld.io)",
                            imgSrc: "https://light.protidhwani.tk/img/BehChalaPagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Bojhena Shey Bojhena _ Title Track _ Soham _ Abir(MP3_160K).mp3",
                            title: "Bojhena Shey Bojhena | Title Track | Soham | Abir ",
                            artist: "SVF",
                            imgSrc: "https://light.protidhwani.tk/img/BojhenaSheyBojhenaTitleTrackSohamAbir.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Bole Ram Bole Ram - SenSongsMp3.Co.mp3",
                            title: "Bole Ram Bole Ram - SenSongsMp3.Co",
                            artist: "Srikrishna & M.L Sruthi",
                            imgSrc: "https://light.protidhwani.tk/img/BoleRamBoleRamSenSongsMp3.Co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Busy Busy-(Mr-Jatt.com).mp3",
                            title: "Busy Busy (Mr-Jatt.com)",
                            artist: "Neha Pandey (Mr-Jatt.com)",
                            imgSrc: "https://light.protidhwani.tk/img/BusyBusyMrJatt.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Butta Bomma-(Telugu-wap.in).mp3",
                            title: "Butta Bomma-(Telugu-wap.in)",
                            artist: "Armaan Malik",
                            imgSrc: "https://light.protidhwani.tk/img/ButtaBommaTeluguwap.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Butta Bomma-Naasongs.fm.mp3",
                            title: "Butta Bomma-Naasongs.fm",
                            artist: "Armaan Malik",
                            imgSrc: "https://light.protidhwani.tk/img/ButtaBommaNaasongs.fm.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Chaiyaan-Main-Saiyaan-Ki_320(PagalWorldl).mp3",
                            title: "Chaiyaan Main Saiyaan Ki(PagalWorldl)",
                            artist: "PagalWorldl.Com",
                            imgSrc: "https://light.protidhwani.tk/img/ChaiyaanMainSaiyaanKiPagalWorldl.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Chalte Chalte (Tutak Tutak Tutiya) Arijit Singh 190Kbps.mp3",
                            title: "Chalte Chalte -  PagalWorld.com",
                            artist: "Arijit Singh -  PagalWorld.com",
                            imgSrc: "https://light.protidhwani.tk/img/ChalteChaltePagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Changubhala-Naasongs.me.mp3",
                            title: "Changubhala-Naasongs.me",
                            artist: "Nutana Mohan-Naasongs.me",
                            imgSrc: "https://light.protidhwani.tk/img/ChangubhalaNaasongs.me.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Chella_Kutti-StarMusiQ.Com.mp3",
                            title: "Chella Kutti-StarMusiQ.Com",
                            artist: "Vijay & Neeti Mohan",
                            imgSrc: "https://light.protidhwani.tk/img/ChellaKuttiStarMusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Chinnanjiru Nilave.mp3",
                            title: "Chinnanjiru Nilave",
                            artist: "A.R. Rahman,Haricharan",
                            imgSrc: "https://light.protidhwani.tk/img/ChinnanjiruNilave.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Dance Song - Fwa Bagha Re __ Latest Dj Song __ फ्व(MP3_160K).mp3",
                            title: "Dance Song - Fwa Bagha Re || Latest Dj Song || फ्व",
                            artist: "Neelam Uttarakhandi",
                            imgSrc: "https://light.protidhwani.tk/img/DanceSongFwaBaghaReLatestDjSongफव.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Darshana-MassTamilan.fm.mp3",
                            title: "Darshana - MassTamilan.fm",
                            artist: "Hesham Abdul Wahab, Darshana Rajendran - MassTamilan.fm",
                            imgSrc: "https://light.protidhwani.tk/img/DarshanaMassTamilan.fm.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Darshana.mp3",
                            title: "Darshana",
                            artist: "Hesham Abdul Wahab,Darshana Rajendran",
                            imgSrc: "https://light.protidhwani.tk/img/Darshana.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Daryaa-(Mr-Jatt.com).mp3",
                            title: "Daryaa (Mr-Jatt.com)",
                            artist: "Shahid Mallya, Ammy Virk (Mr-Jatt.com)",
                            imgSrc: "https://light.protidhwani.tk/img/DaryaaMrJatt.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Degar_Mari_Mori_Jam_(Simanta_Shekhar)(Asomi.In).mp3",
                            title: "Degar Mari Mori Jam -  SongsLoft.in",
                            artist: "Simanta Shekhar, Bornali Kalita",
                            imgSrc: "https://light.protidhwani.tk/img/DegarMariMoriJamSongsLoft.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Devare.mp3",
                            title: "Devare [KannadaMasti.Net]",
                            artist: "Armaan Malik",
                            imgSrc: "https://light.protidhwani.tk/img/DevareKannadaMasti.Net.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Dhulikona_320(AxomWap.com).mp3",
                            title: "Dhulikona_320(AxomWap.com)",
                            artist: "Zubeen Garg ,Zublee ,Panchana Rabha",
                            imgSrc: "https://light.protidhwani.tk/img/Dhulikona320AxomWap.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Digu Digu Digu Naaga.mp3",
                            title: "Digu Digu Digu Naaga :: SenSongsMp3.Com",
                            artist: "NA",
                            imgSrc: "https://light.protidhwani.tk/img/DiguDiguDiguNaagaSenSongsMp3.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Dippam Dappam (From Kaathuvaakula Rendu Kaadhal).mp3",
                            title: "Dippam Dappam (From Kaathuvaakula Rendu Kaadhal)",
                            artist: "Anirudh Ravichander,Anthony Daasan",
                            imgSrc: "https://light.protidhwani.tk/img/DippamDappamFromKaathuvaakulaRenduKaadhal.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ek Ladki Ko Dekha Toh Aisa Laga.mp3",
                            title: "Ek Ladki Ko Dekha Toh Aisa Laga Title Song - Pagalworld.io",
                            artist: "Darshan Raval - Pagalworld.io",
                            imgSrc: "https://light.protidhwani.tk/img/EkLadkiKoDekhaTohAisaLagaTitleSongPagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Emo Emo.mp3",
                            title: "Emo Emo",
                            artist: "Armaan Malik,Shreya Ghoshal",
                            imgSrc: "https://light.protidhwani.tk/img/EmoEmo.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/En Rojaa Neeye (From Kushi).mp3",
                            title: "En Rojaa Neeye (From Kushi)",
                            artist: "Hesham Abdul Wahab",
                            imgSrc: "https://light.protidhwani.tk/img/EnRojaaNeeyeFromKushi.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Enga Pora Dora-SenSongsMp3.Co.mp3",
                            title: "Enga Pora Dora-SenSongsMp3.Co",
                            artist: "Mervin Solomon",
                            imgSrc: "https://light.protidhwani.tk/img/EngaPoraDoraSenSongsMp3.Co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ennodu Nee Irundhaal.mp3",
                            title: "Ennodu Nee Irundhaal",
                            artist: "A.R. Rahman,Sid Sriram,Sunitha Sarathy",
                            imgSrc: "https://light.protidhwani.tk/img/EnnoduNeeIrundhaal.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ennodu_Nee_Irundhal-StarMusiQ.Com.mp3",
                            title: "Ennodu Nee Irundhal-StarMusiQ.Com",
                            artist: "Sid Sriram, Sunitha Sarathy",
                            imgSrc: "https://light.protidhwani.tk/img/EnnoduNeeIrundhalStarMusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ennodu_Nee_Irundhal_(Reprise)-StarMusiQ.Com.mp3",
                            title: "Ennodu Nee Irundhal (Reprise)-StarMusiQ.Com",
                            artist: "Chinmayi, Sid Sriram",
                            imgSrc: "https://light.protidhwani.tk/img/EnnoduNeeIrundhalRepriseStarMusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ethir-Neechal.mp3",
                            title: "Ethir Neechal - MassTamilan.com",
                            artist: "Anirudh feat. YOYO Honey Singh and Hip Hop Tamizha Adhi - MassTamilan.com",
                            imgSrc: "https://light.protidhwani.tk/img/EthirNeechalMassTamilan.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Etho Mazhayil.mp3",
                            title: "Etho Mazhayil",
                            artist: "Vijay Yesudas,Shweta Mohan,Prince George",
                            imgSrc: "https://light.protidhwani.tk/img/EthoMazhayil.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Filhall B Praak 320kbps(Newmirchi.in).mp3",
                            title: "Filhall B Praak 320kbps(Newmirchi.in)",
                            artist: "Newmirchi.in",
                            imgSrc: "https://light.protidhwani.tk/img/FilhallBPraak320kbpsNewmirchi.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Gainey Dajai.mp3",
                            title: "Gainey Dajai",
                            artist: "Trishna Gurung",
                            imgSrc: "https://light.protidhwani.tk/img/GaineyDajai.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Galliyan-Dil Mein Ho Tum.mp3",
                            title: "Galliyan-Dil Mein Ho Tum (Pagalworld.io)",
                            artist: "Jonita Gandhi, Salim Merchant (Pagalworld.io)",
                            imgSrc: "https://light.protidhwani.tk/img/GalliyanDilMeinHoTumPagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Gerua - Shahrukh Khan.mp3",
                            title: "Gerua - PagalWorld.com",
                            artist: "Antara Mitra, Arijit Singh",
                            imgSrc: "https://light.protidhwani.tk/img/GeruaPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Gudilo Badilo Madilo-SenSongsMp3.Co.mp3",
                            title: "Gudilo Badilo Madilo-SenSongsMp3.Co",
                            artist: "M. L. R. Karthikeyan, K. S. Chithra",
                            imgSrc: "https://light.protidhwani.tk/img/GudiloBadiloMadiloSenSongsMp3.Co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Halena.mp3",
                            title: "Halena - MassTamilan.com",
                            artist: "Christopher Stanley, Abhay Jodhpurkar & Ujjayinee Roy - MassTamilan.com",
                            imgSrc: "https://light.protidhwani.tk/img/HalenaMassTamilan.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Hayyoda (From Jawan).mp3",
                            title: "Hayyoda (From Jawan)",
                            artist: "Anirudh Ravichander,Priya Mali",
                            imgSrc: "https://light.protidhwani.tk/img/HayyodaFromJawan.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Humnava Mere - Baarish.mp3",
                            title: "Humnava Mere Baarish (Pagalworld.Live)",
                            artist: "Aditya Narayan, Dhvani Bhanushali (Pagalworld.Live)",
                            imgSrc: "https://light.protidhwani.tk/img/HumnavaMereBaarishPagalworld.Live.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Humsafar (Acoustic) Dhvani Bhanushali 320Kbps.mp3",
                            title: "Humsafar (Acoustic) - PagalWorld.cool",
                            artist: "Dhvani Bhanushali - PagalWorld.com",
                            imgSrc: "https://light.protidhwani.tk/img/HumsafarAcousticPagalWorld.cool.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Hututu.mp3",
                            title: "Hututu",
                            artist: "A.R. Rahman,Shashaa Tirupati",
                            imgSrc: "https://light.protidhwani.tk/img/Hututu.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Idhayathai Yedho Ondru.mp3",
                            title: "Idhayathai Yedho Ondru",
                            artist: "Harris Jayaraj,Chinmayi",
                            imgSrc: "https://light.protidhwani.tk/img/IdhayathaiYedhoOndru.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ik Kahani - Gajendra Verma -320Kbps [DJMaza.Info].mp3",
                            title: "Ik Kahani - DJMaza.Info",
                            artist: "Gajendra Verma",
                            imgSrc: "https://light.protidhwani.tk/img/IkKahaniDJMaza.Info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ik Vaari Aa-Nadaan Parindey.mp3",
                            title: "Ik Vaari Aa-Nadaan Parindey-Tum Ho Toh (Pagalworld.io)",
                            artist: "Shirley Setia , Jubin Nautiyal (Pagalworld.io)",
                            imgSrc: "https://light.protidhwani.tk/img/IkVaariAaNadaanParindeyTumHoTohPagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ishare Tere - Guru Randhawa.mp3",
                            title: "Ishare Tere - Pagalworld.co",
                            artist: "Guru Randhawa , Dhvani Bhanushali - Pagalworld.co",
                            imgSrc: "https://light.protidhwani.tk/img/IshareTerePagalworld.co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ishq Mitha.mp3",
                            title: "Ishq Mitha - Pagalworld.io",
                            artist: "Navraj Hans, Harshdeep Kaur",
                            imgSrc: "https://light.protidhwani.tk/img/IshqMithaPagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Jab Koi Baat - Atif Aslam n Shirley Setia 320Kbps.mp3",
                            title: "Jab Koi Baat - PagalWorld.info",
                            artist: "Atif Aslam, Shirley Setia , DJ Chetas",
                            imgSrc: "https://light.protidhwani.tk/img/JabKoiBaatPagalWorld.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Jajabori Priya - Dikshu (Theatre Surjya 2018-19)(DjAssam.Com).mp3",
                            title: "Jajabori Priya - Dikshu (Theatre Surjya 2018-19)(DjAssam.Com)",
                            artist: "Dikshu",
                            imgSrc: "https://light.protidhwani.tk/img/JajaboriPriyaDikshuTheatreSurjya201819DjAssam.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Janib - Sajde Kiye Hain.mp3",
                            title: "Janib - Sajde Kiye Hain (Pagalworld.Live)",
                            artist: "Javed Ali , Harshdeep Kaur (Pagalworld.Live)",
                            imgSrc: "https://light.protidhwani.tk/img/JanibSajdeKiyeHainPagalworld.Live.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Jeevamshamayi (From Theevandi).mp3",
                            title: "Jeevamshamayi (From Theevandi)",
                            artist: "K. S. Harisankar,Shreya Ghoshal,Kailas",
                            imgSrc: "https://light.protidhwani.tk/img/JeevamshamayiFromTheevandi.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Jeevana.mp3",
                            title: "Jeevana",
                            artist: "K. S. Harisankar,Swathy Manu,Vijin Cholakkal",
                            imgSrc: "https://light.protidhwani.tk/img/Jeevana.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Jheeni Re Jheeni (Issaq).mp3",
                            title: "08 - Jheeni Re Jheeni (Issaq) (Mp3wale.com)",
                            artist: "Rashid Khan & Prathiba Bhagel",
                            imgSrc: "https://light.protidhwani.tk/img/08JheeniReJheeniIssaqMp3wale.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Jheeni Re Jheeni_320(Ghantalele.com).mp3",
                            title: "Jheeni Re Jheeni(Ghantalele.com)",
                            artist: "Ustad Rashid Khan, Pratibha Baghel - Ghantalele.com",
                            imgSrc: "https://light.protidhwani.tk/img/JheeniReJheeniGhantalele.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kaadhal En Kaviye - From Salmon 3D.mp3",
                            title: "Kaadhal En Kaviye - From Salmon 3D",
                            artist: "Sid Sriram,Sreejith Edavana",
                            imgSrc: "https://light.protidhwani.tk/img/KaadhalEnKaviyeFromSalmon3D.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kaalam_Yen_Kadhali-StarMusiQ.Com.mp3",
                            title: "Kaalam Yen Kadhali-StarMusiQ.Com",
                            artist: "Benny Dayal, Shashwat Singh, Abhay Jodhpurkar",
                            imgSrc: "https://light.protidhwani.tk/img/KaalamYenKadhaliStarMusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kaanan Thonnununde(PagalWorld).mp3",
                            title: "Kaanan Thonnununde(PagalWorld)",
                            artist: "PagaliWorld.Com",
                            imgSrc: "https://light.protidhwani.tk/img/KaananThonnunundePagalWorld.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kaanan Thonnununde.mp3",
                            title: "Kaanan Thonnununde",
                            artist: "Sidharth Sankar,Siddharth Menon",
                            imgSrc: "https://light.protidhwani.tk/img/KaananThonnununde.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kaathalae Kaathalae - Version 1.mp3",
                            title: "Kaathalae Kaathalae - Version 1",
                            artist: "Govind Vasantha,Chinmayi",
                            imgSrc: "https://light.protidhwani.tk/img/KaathalaeKaathalaeVersion1.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kabhi Jo Badal Barse-Dil De Diya Hai.mp3",
                            title: "Kabhi Jo Badal Barse-Dil De Diya Hai - Pagalworld.Live",
                            artist: "Mohammed Irfan , Tulsi Kumar - Pagalworld.Live",
                            imgSrc: "https://light.protidhwani.tk/img/KabhiJoBadalBarseDilDeDiyaHaiPagalworld.Live.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kabira.mp3",
                            title: "Kabira",
                            artist: "Pritam,Tochi Raina,Rekha Bhardwaj",
                            imgSrc: "https://light.protidhwani.tk/img/Kabira.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kadalanthe.mp3",
                            title: "Kadalanthe [KannadaMasti.Com]",
                            artist: "Justin Prabhakaran, Aishwarya Ravichandran, Sid Sriram",
                            imgSrc: "https://light.protidhwani.tk/img/KadalantheKannadaMasti.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kahin Toh Hogi Woh-Teri Aahatein.mp3",
                            title: "Kahin Toh Hogi Woh-Teri Aahatein (Pagalworld.Live)",
                            artist: "Neeti Mohan, Shekhar Ravjiani (Pagalworld.Live)",
                            imgSrc: "https://light.protidhwani.tk/img/KahinTohHogiWohTeriAahateinPagalworld.Live.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kaise Bataaoon (3G) KK.mp3",
                            title: "Kaise Bataaoon (3G) KK pagalworld.com",
                            artist: "pagalworld.com",
                            imgSrc: "https://light.protidhwani.tk/img/KaiseBataaoon3GKKpagalworld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kaise Hua - Kabir Singh.mp3",
                            title: "Kaise Hua - Pagalworld.Live",
                            artist: "Vishal Mishra",
                            imgSrc: "https://light.protidhwani.tk/img/KaiseHuaPagalworld.Live.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kalapakkaara (From King of Kotha).mp3",
                            title: "Kalapakkaara (From King of Kotha)",
                            artist: "Jakes Bejoy,Shreya Ghoshal,Benny Dayal,Fejo,Joe Paul",
                            imgSrc: "https://light.protidhwani.tk/img/KalapakkaaraFromKingofKotha.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kamini.mp3",
                            title: "Kamini",
                            artist: "K. S. Harisankar,Arun Muraleedharan",
                            imgSrc: "https://light.protidhwani.tk/img/Kamini.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kanchanjangha(MP3_160K).mp3",
                            title: "Kanchanjangha",
                            artist: "Zubeen Garg - Topic",
                            imgSrc: "https://light.protidhwani.tk/img/Kanchanjangha.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kannadi Koodum Kootti - Recreated Version.mp3",
                            title: "Kannadi Koodum Kootti - Recreated Version",
                            artist: "Sanah Moidutty",
                            imgSrc: "https://light.protidhwani.tk/img/KannadiKoodumKoottiRecreatedVersion.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kattu Mooliyo.mp3",
                            title: "Kattu Mooliyo",
                            artist: "Vineeth Sreenivasan",
                            imgSrc: "https://light.protidhwani.tk/img/KattuMooliyo.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kesariya_192(PagalWorld.com.se).mp3",
                            title: "Kesariya(PagalWorld.com.se)",
                            artist: "Arijit Singh",
                            imgSrc: "https://light.protidhwani.tk/img/KesariyaPagalWorld.com.se.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ki Naam Di Maatim (From Dr. Bezboruah2).mp3",
                            title: "Ki Naam Di Maatim (From Dr. Bezboruah2)",
                            artist: "Romen Barua,Zubeen Garg,Bhaswati Bharati",
                            imgSrc: "https://light.protidhwani.tk/img/KiNaamDiMaatimFromDr.Bezboruah2.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kina - Can We Kiss Forever_ (Lyrics) ft. Adriana P(MP3_160K).mp3",
                            title: "Kina - Can We Kiss Forever (Lyrics) ft. Adriana P",
                            artist: "NA",
                            imgSrc: "https://light.protidhwani.tk/img/KinaCanWeKissForeverLyricsft.AdrianaP.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kithe Reh Gaya - Neeti Mohan.mp3",
                            title: "Kithe Reh Gaya - SongsMp3.Org",
                            artist: "Neeti Mohan",
                            imgSrc: "https://light.protidhwani.tk/img/KitheRehGayaSongsMp3.Org.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kudukku.mp3",
                            title: "Kudukku",
                            artist: "Shaan Rahman,Vineeth Sreenivasan",
                            imgSrc: "https://light.protidhwani.tk/img/Kudukku.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Kumkumala (From Brahmastra (Telugu)).mp3",
                            title: "Kumkumala (From Brahmastra (Telugu))",
                            artist: "Pritam,Sid Sriram",
                            imgSrc: "https://light.protidhwani.tk/img/KumkumalaFromBrahmastraTelugu.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Lailakame.mp3",
                            title: "Lailakame",
                            artist: "Haricharan,Rahul Raj",
                            imgSrc: "https://light.protidhwani.tk/img/Lailakame.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Lehanga-(Mr-Jatt.com).mp3",
                            title: "Lehanga (Mr-Jatt.com)",
                            artist: "Jass Manak (Mr-Jatt.com)",
                            imgSrc: "https://light.protidhwani.tk/img/LehangaMrJatt.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Leja Re.mp3",
                            title: "Leja Re - Pagalworld.io",
                            artist: "Dhvani Bhanushali - Pagalworld.io",
                            imgSrc: "https://light.protidhwani.tk/img/LejaRePagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Maangalyam - From Bangalore Days.mp3",
                            title: "Maangalyam - From Bangalore Days",
                            artist: "Gopi Sundar,Vijay Yesudas,Sachin Warrier,Divya S Menon",
                            imgSrc: "https://light.protidhwani.tk/img/MaangalyamFromBangaloreDays.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Maathare - SenSongsMp3.Co.mp3",
                            title: "Maathare :: SenSongsMp3.Co",
                            artist: "A.R Rahman, Chinmayi Sripada",
                            imgSrc: "https://light.protidhwani.tk/img/MaathareSenSongsMp3.Co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Macho [Starmusiq.info].mp3",
                            title: "Macho [Starmusiq.info]",
                            artist: "Sid Sriram and Shweta Mohan",
                            imgSrc: "https://light.protidhwani.tk/img/MachoStarmusiq.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Maha Adhbhutham.mp3",
                            title: "Maha Adhbhutham",
                            artist: "Nutana Mohan",
                            imgSrc: "https://light.protidhwani.tk/img/MahaAdhbhutham.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Main Rahoon Ya Na Rahoon-Dil Kyun Yeh Mera.mp3",
                            title: "Main Rahoon Ya Na Rahoon-Dil Kyun Yeh Mera (Pagalworld.Live)",
                            artist: "Amaal Mallik , Prakriti Kakar (Pagalworld.Live)",
                            imgSrc: "https://light.protidhwani.tk/img/MainRahoonYaNaRahoonDilKyunYehMeraPagalworld.Live.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Maiya Mainu Yaad Aave_320(PagalWorldl).mp3",
                            title: "Maiya Mainu Yaad Aave(PagalWorldl)",
                            artist: "PagalWorldl.Com",
                            imgSrc: "https://light.protidhwani.tk/img/MaiyaMainuYaadAavePagalWorldl.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Makhna - Drive.mp3",
                            title: "Makhna [Pagalworld.name]",
                            artist: "Yasser Desai, Asees Kaur [Pagalworld.name]",
                            imgSrc: "https://light.protidhwani.tk/img/MakhnaPagalworld.name.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Malare-Ninne.mp3",
                            title: "Malare Ninne - MassTamilan.com",
                            artist: "Vijay Yesudas - MassTamilan.com",
                            imgSrc: "https://light.protidhwani.tk/img/MalareNinneMassTamilan.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Malare.mp3",
                            title: "Malare",
                            artist: "Vijay Yesudas,Rajesh Murugesan",
                            imgSrc: "https://light.protidhwani.tk/img/Malare.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Manasellam Mazhaiye.mp3",
                            title: "Manasellam Mazhaiye",
                            artist: "Sonu Nigam,Saindhavi,G. V. Prakash",
                            imgSrc: "https://light.protidhwani.tk/img/ManasellamMazhaiye.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Manasellam.mp3",
                            title: "Manasellam - MassTamilan.com",
                            artist: "Sonu Nigam,Saindhavi & GV Prakash Kumar - MassTamilan.com",
                            imgSrc: "https://light.protidhwani.tk/img/ManasellamMassTamilan.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Manasse-Manasse-MassTamilan.fm.mp3",
                            title: "Manasse Manasse - MassTamilan.fm",
                            artist: "Vineeth Sreenivasan - MassTamilan.fm",
                            imgSrc: "https://light.protidhwani.tk/img/ManasseManasseMassTamilan.fm.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mandaara - SenSongsMp3.Co.mp3",
                            title: "Mandaara :: SenSongsMp3.Co",
                            artist: "Thaman S., Shreya Ghoshal",
                            imgSrc: "https://light.protidhwani.tk/img/MandaaraSenSongsMp3.Co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mandaarame.mp3",
                            title: "Mandaarame",
                            artist: "Job Kurian,Shaan Rahman",
                            imgSrc: "https://light.protidhwani.tk/img/Mandaarame.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mann Kesar Kesar (From Meenakshi Sundareshwar).mp3",
                            title: "Mann Kesar Kesar (From Meenakshi Sundareshwar)",
                            artist: "Justin Prabhakaran,Shashwat Singh,Aanandi Joshi,Goldie Sohel,Raj Shekhar",
                            imgSrc: "https://light.protidhwani.tk/img/MannKesarKesarFromMeenakshiSundareshwar.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Maya.mp3",
                            title: "Maya",
                            artist: "Zubeen Garg",
                            imgSrc: "https://light.protidhwani.tk/img/Maya.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mayabini Ratir Bukut - Zubeen Garg-(MirchiAssam.Com).mp3",
                            title: "Mayabini Ratir Bukut - Zubeen Garg-(MirchiAssam.Com)",
                            artist: "www.MirchiAssam.com",
                            imgSrc: "https://light.protidhwani.tk/img/MayabiniRatirBukutZubeenGargMirchiAssam.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Maybe My Soulmate Died_320(PaglaSongs).mp3",
                            title: "Maybe My Soulmate Died(PaglaSongs)",
                            artist: "PaglaSongs.Com",
                            imgSrc: "https://light.protidhwani.tk/img/MaybeMySoulmateDiedPaglaSongs.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Meghor.mp3",
                            title: "Meghor",
                            artist: "Zubeen Garg",
                            imgSrc: "https://light.protidhwani.tk/img/Meghor.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mei_Nigara-StarMusiQ.Com.mp3",
                            title: "Mei Nigara-StarMusiQ.Com",
                            artist: "Sid Sriram, Sanah Moidutty, Jonita Gandhi",
                            imgSrc: "https://light.protidhwani.tk/img/MeiNigaraStarMusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mera Intezaar Karna - Khuda Haafiz 128 Kbps.mp3",
                            title: "Mera Intezaar Karna",
                            artist: "Armaan Malik",
                            imgSrc: "https://light.protidhwani.tk/img/MeraIntezaarKarna.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mere Bina (Crook) - K.K - 320Kbps.mp3",
                            title: "Mere Bina - PagalWorld.cool",
                            artist: "K.K. (PagalWorld.cool)",
                            imgSrc: "https://light.protidhwani.tk/img/MereBinaPagalWorld.cool.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mere Bina.mp3",
                            title: "Mere Bina",
                            artist: "Pritam,Nikhil D'Souza",
                            imgSrc: "https://light.protidhwani.tk/img/MereBina.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mersal Arasan [Starmusiq.info].mp3",
                            title: "Mersal Arasan [Starmusiq.info]",
                            artist: "NA",
                            imgSrc: "https://light.protidhwani.tk/img/MersalArasanStarmusiq.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mizhiyariyaathe - Version, 1.mp3",
                            title: "Mizhiyariyaathe - Version, 1",
                            artist: "K. J. Yesudas",
                            imgSrc: "https://light.protidhwani.tk/img/MizhiyariyaatheVersion1.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Morom_Buwoti_Noi_-_Rakesh_Reeyan(Asomi.In).mp3",
                            title: "Morom Buwoti Noi - Rakesh Reeyan-(Asomi.in)",
                            artist: "Rakesh Reeyan",
                            imgSrc: "https://light.protidhwani.tk/img/MoromBuwotiNoiRakeshReeyanAsomi.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Motoliya.mp3",
                            title: "Motoliya",
                            artist: "Zubeen Garg,Mahalakshmi Iyer",
                            imgSrc: "https://light.protidhwani.tk/img/Motoliya.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mudhal Murai %5bStarmusiq.cc.mp3",
                            title: "Mudhal Murai [Starmusiq.cc]",
                            artist: "HarishRagavendra, Swetha Menon, NSK Ramya, Karthik",
                            imgSrc: "https://light.protidhwani.tk/img/MudhalMuraiStarmusiq.cc.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mudhal%20Murai%20%5bStarmusiq.cc.mp3",
                            title: "Mudhal Murai [Starmusiq.cc]",
                            artist: "HarishRagavendra, Swetha Menon, NSK Ramya, Karthik",
                            imgSrc: "https://light.protidhwani.tk/img/MudhalMuraiStarmusiq.cc.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mugdho Hiya Mur.mp3",
                            title: "Mugdho Hiya Mur",
                            artist: "Zubeen Garg",
                            imgSrc: "https://light.protidhwani.tk/img/MugdhoHiyaMur.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mun Andhi.mp3",
                            title: "Mun Andhi",
                            artist: "Harris Jayaraj,Karthik",
                            imgSrc: "https://light.protidhwani.tk/img/MunAndhi.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Mun-Andhi.mp3",
                            title: "Mun Andhi - MassTamilan.com",
                            artist: "Karthik & Megha - MassTamilan.com",
                            imgSrc: "https://light.protidhwani.tk/img/MunAndhiMassTamilan.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Na Roja Nuvve (From Kushi).mp3",
                            title: "Na Roja Nuvve (From Kushi)",
                            artist: "Hesham Abdul Wahab",
                            imgSrc: "https://light.protidhwani.tk/img/NaRojaNuvveFromKushi.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Naalo Maimarapu-Naasongs.me.mp3",
                            title: "Naalo Maimarapu-Naasongs.me",
                            artist: "Mohana Bhogaraju-Naasongs.me",
                            imgSrc: "https://light.protidhwani.tk/img/NaaloMaimarapuNaasongs.me.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Naan Un.mp3",
                            title: "Naan Un",
                            artist: "Arijit Singh,Chinmayi",
                            imgSrc: "https://light.protidhwani.tk/img/NaanUn.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Naan_Un-StarMusiQ.Com.mp3",
                            title: "Naan Un-StarMusiQ.Com",
                            artist: "Arijit Singh, Chinmayi",
                            imgSrc: "https://light.protidhwani.tk/img/NaanUnStarMusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Naane Varugiraen.mp3",
                            title: "Naane Varugiraen",
                            artist: "A.R. Rahman,Shashaa Tirupati,Sathyaprakash",
                            imgSrc: "https://light.protidhwani.tk/img/NaaneVarugiraen.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Nagumo-Revival-MassTamilan.fm.mp3",
                            title: "Nagumo Revival - MassTamilan.fm",
                            artist: "Hesham Abdul Wahab, Arvind Venugopal, Swetha Ashok - MassTamilan.fm",
                            imgSrc: "https://light.protidhwani.tk/img/NagumoRevivalMassTamilan.fm.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Namo Namo - Kedarnath.mp3",
                            title: "Namo Namo - Pagalworld.org",
                            artist: "Amit Trivedi - Pagalworld.org",
                            imgSrc: "https://light.protidhwani.tk/img/NamoNamoPagalworld.org.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Nee Himamazhayayi - From Edakkad Battalion 06.mp3",
                            title: "Nee Himamazhayayi - From Edakkad Battalion 06",
                            artist: "Kailas,K. S. Harisankar,Nithya Mammen",
                            imgSrc: "https://light.protidhwani.tk/img/NeeHimamazhayayiFromEdakkadBattalion06.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Neethanae [Starmusiq.info].mp3",
                            title: "Neethanae [Starmusiq.info]",
                            artist: "A R Rahman, Shreya Ghoshal",
                            imgSrc: "https://light.protidhwani.tk/img/NeethanaeStarmusiq.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Neethoney_Dance-StarMusiQ.Com.mp3",
                            title: "Neethoney Dance-StarMusiQ.Com",
                            artist: "Hiphop Tamizha, Nikitha Gandhi",
                            imgSrc: "https://light.protidhwani.tk/img/NeethoneyDanceStarMusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Neeye.mp3",
                            title: "Neeye",
                            artist: "Yazin Nizar,Sharanya Srinivas",
                            imgSrc: "https://light.protidhwani.tk/img/Neeye.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Neeyum Naanum.mp3",
                            title: "Neeyum Naanum",
                            artist: "Anirudh Ravichander,Neeti Mohan",
                            imgSrc: "https://light.protidhwani.tk/img/NeeyumNaanum.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Nenjame Nenjame.mp3",
                            title: "Nenjame Nenjame",
                            artist: "A.R. Rahman,Vijay Yesudas,Shakthisree Gopalan",
                            imgSrc: "https://light.protidhwani.tk/img/NenjameNenjame.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/O Saathi - Humsafar.mp3",
                            title: "O Saathi Humsafar (Pagalworld.Live)",
                            artist: "Nikhita Gandhi , Ash King (Pagalworld.Live)",
                            imgSrc: "https://light.protidhwani.tk/img/OSaathiHumsafarPagalworld.Live.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/O Sone O Sone.mp3",
                            title: "O Sone O Sone - isaimini.co",
                            artist: "Javed Ali, Priya Subramaniyan, MC-Vickey",
                            imgSrc: "https://light.protidhwani.tk/img/OSoneOSoneisaimini.co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/O%20Sone%20O%20Sone.mp3",
                            title: "O Sone O Sone - isaimini.co",
                            artist: "Javed Ali, Priya Subramaniyan, MC-Vickey",
                            imgSrc: "https://light.protidhwani.tk/img/OSoneOSoneisaimini.co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Oh Baby-Naasongs.me.mp3",
                            title: "Oh Baby-Naasongs.me",
                            artist: "Anurag Kulkarni-Naasongs.me",
                            imgSrc: "https://light.protidhwani.tk/img/OhBabyNaasongs.me.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Onakka-Munthiri-MassTamilan.fm.mp3",
                            title: "Onakka Munthiri - MassTamilan.fm",
                            artist: "Divya Vineeth - MassTamilan.fm",
                            imgSrc: "https://light.protidhwani.tk/img/OnakkaMunthiriMassTamilan.fm.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Oru Nokku.mp3",
                            title: "Oru Nokku",
                            artist: "Karthik",
                            imgSrc: "https://light.protidhwani.tk/img/OruNokku.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Oru Paathi Kadhavu - Isaimini.Audio.mp3",
                            title: "Oru Paathi Kadhavu - Isaimini.Audio",
                            artist: "Haricharan,Vandana Srinivasan - Isaimini.Audio",
                            imgSrc: "https://light.protidhwani.tk/img/OruPaathiKadhavuIsaimini.Audio.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Oru_Paathi_Kadhavu-VmusiQ.Com.mp3",
                            title: "Oru Paathi Kadhavu-VmusiQ.Com",
                            artist: "Haricharan,Vandana Srinivasan",
                            imgSrc: "https://light.protidhwani.tk/img/OruPaathiKadhavuVmusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Othaiyadi Pathayila.mp3",
                            title: "Othaiyadi Pathayila",
                            artist: "Dhibu Ninan Thomas,Anirudh Ravichander",
                            imgSrc: "https://light.protidhwani.tk/img/OthaiyadiPathayila.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Pagsamo.mp3",
                            title: "Pagsamo",
                            artist: "Arthur Nery",
                            imgSrc: "https://light.protidhwani.tk/img/Pagsamo.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Panchana(Asomi.In).mp3",
                            title: "Panchana (Asomi.in)",
                            artist: "Zubeen Garg, Panchana Rabha",
                            imgSrc: "https://light.protidhwani.tk/img/PanchanaAsomi.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Parayuvaan - From Ishq.mp3",
                            title: "Parayuvaan - From Ishq",
                            artist: "Sid Sriram,Neha S. Nair,Jakes Bejoy,Joe Paul",
                            imgSrc: "https://light.protidhwani.tk/img/ParayuvaanFromIshq.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Pareshanura-StarMusiQ.Com.mp3",
                            title: "Pareshanura-StarMusiQ.Com",
                            artist: "Padmalatha, Vishnu Priya",
                            imgSrc: "https://light.protidhwani.tk/img/PareshanuraStarMusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Pavizha Mazha.mp3",
                            title: "Pavizha Mazha",
                            artist: "P S Jayhari,K. S. Harisankar",
                            imgSrc: "https://light.protidhwani.tk/img/PavizhaMazha.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Payye Payye.mp3",
                            title: "Payye Payye",
                            artist: "Shaan Rahman,K. S. Harisankar",
                            imgSrc: "https://light.protidhwani.tk/img/PayyePayye.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Pookkal Pookkum.mp3",
                            title: "Pookkal Pookkum",
                            artist: "G. V. Prakash,Roop Kumar Rathod,Harini,Andrea Jeremiah",
                            imgSrc: "https://light.protidhwani.tk/img/PookkalPookkum.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Pookkalae Sattru Oyivedungal.mp3",
                            title: "Pookkalae Sattru Oyivedungal",
                            artist: "A.R. Rahman,Haricharan,Shreya Ghoshal",
                            imgSrc: "https://light.protidhwani.tk/img/PookkalaeSattruOyivedungal.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Pookkale_Satru-StarMusiQ.Com.mp3",
                            title: "Pookkale Satru-StarMusiQ.Com",
                            artist: "Haricharan, Shreya Ghoshal",
                            imgSrc: "https://light.protidhwani.tk/img/PookkaleSatruStarMusiQ.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Priti Bhora_320(AxomWap.com).mp3",
                            title: "Priti Bhora_320(AxomWap.com)",
                            artist: "Zubeen Garg ,Gayatri Hazarika",
                            imgSrc: "https://light.protidhwani.tk/img/PritiBhora320AxomWap.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Pulkodiyil Thoomani (From _Take Off_).mp3",
                            title: "Pulkodiyil Thoomani (From Take Off)",
                            artist: "Shaan Rahman,Hesham Abdul Wahab,Rafeeq Ahammed",
                            imgSrc: "https://light.protidhwani.tk/img/PulkodiyilThoomaniFromTakeOff.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Puthiyoru-Lokam-MassTamilan.fm.mp3",
                            title: "Puthiyoru Lokam - MassTamilan.fm",
                            artist: "Hesham Abdul Wahab, Vimal Roy, Bhadra Rajin - MassTamilan.fm",
                            imgSrc: "https://light.protidhwani.tk/img/PuthiyoruLokamMassTamilan.fm.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Qismat (Full Video) _ Ammy Virk _ Sargun Mehta _ J(MP3_160K).mp3",
                            title: "Qismat (Full Video) | Ammy Virk | Sargun Mehta | J",
                            artist: "Speed Records",
                            imgSrc: "https://light.protidhwani.tk/img/QismatFullVideoAmmyVirkSargunMehtaJ.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Qismat - MP3Vista.Com.mp3",
                            title: "Qismat - MP3Vista.Com",
                            artist: "Ammy Virk",
                            imgSrc: "https://light.protidhwani.tk/img/QismatMP3Vista.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/RAMDHENU __ VREEGU KASHYAP DEVOLEENA __ PINKAL PRA(MP3_160K).mp3",
                            title: "RAMDHENU || VREEGU KASHYAP DEVOLEENA || PINKAL PRA",
                            artist: "VK Entertainment",
                            imgSrc: "https://light.protidhwani.tk/img/RAMDHENUVREEGUKASHYAPDEVOLEENAPINKALPRA.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Raabta (Kehte Hain Khuda Ne) [From Agent Vinod].mp3",
                            title: "Raabta (Kehte Hain Khuda Ne) [From Agent Vinod]",
                            artist: "Shreya Ghoshal,Arijit Singh",
                            imgSrc: "https://light.protidhwani.tk/img/RaabtaKehteHainKhudaNeFromAgentVinod.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Rangi Saari Kavita Seth(MrSong.In).mp3",
                            title: "Rangi Saari Kavita Seth(MrSong.In)",
                            artist: "DJSongi.Com",
                            imgSrc: "https://light.protidhwani.tk/img/RangiSaariKavitaSethMrSong.In.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ranjha - Shershaah.mp3",
                            title: "Ranjha (Pagalworld.pw)",
                            artist: "B Praak (Pagalworld.pw)",
                            imgSrc: "https://light.protidhwani.tk/img/RanjhaPagalworld.pw.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ratti Ratti Reza Reza.mp3",
                            title: "Ratti Ratti Reza Reza",
                            artist: "Justin Prabhakaran,Abhay Jodhpurkar,Shreya Ghoshal,Raj Shekhar",
                            imgSrc: "https://light.protidhwani.tk/img/RattiRattiRezaReza.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ronole Jau - Shankuraj Konwar (Official Lyric Vide(MP3_160K).mp3",
                            title: "Ronole Jau - Shankuraj Konwar (Official Lyric Vide",
                            artist: "Shankuraj & Project Baartalaap",
                            imgSrc: "https://light.protidhwani.tk/img/RonoleJauShankurajKonwarOfficialLyricVide.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Saayaali-MassTamilan.com.mp3",
                            title: "Saayaali - MassTamilan.com",
                            artist: "D. Sathyaprakash, Chinmayi - MassTamilan.com",
                            imgSrc: "https://light.protidhwani.tk/img/SaayaaliMassTamilan.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Saibo.mp3",
                            title: "Saibo",
                            artist: "Sachin-Jigar,Shreya Ghoshal,Tochi Raina",
                            imgSrc: "https://light.protidhwani.tk/img/Saibo.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Sainika SenSongs Mp3.Co.mp3",
                            title: "Sainika :: SenSongsMp3.Co",
                            artist: "Vishal-Shekhar & Vishal Dadlani",
                            imgSrc: "https://light.protidhwani.tk/img/SainikaSenSongsMp3.Co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Sajde (Khatta Meetha) - K.K - 320Kbps.mp3",
                            title: "Sajde - PagalWorld.cool",
                            artist: "K.K. (PagalWorld.cool)",
                            imgSrc: "https://light.protidhwani.tk/img/SajdePagalWorld.cool.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Sajde.mp3",
                            title: "Sajde",
                            artist: "Shankar-Ehsaan-Loy,Arijit Singh,Nihira Joshi Deshpande,Gulzar",
                            imgSrc: "https://light.protidhwani.tk/img/Sajde.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Sakhiyaan-320KBPS-Songspk89.in_.mp3",
                            title: "Sakhiyaan-(Full-Song) (MP3Tau.Com)",
                            artist: "Maninder Buttar",
                            imgSrc: "https://light.protidhwani.tk/img/SakhiyaanFullSongMP3Tau.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Sakhiyeee - From Thrissur Pooram.mp3",
                            title: "Sakhiyeee - From Thrissur Pooram",
                            artist: "Haricharan,Ratheesh Vega",
                            imgSrc: "https://light.protidhwani.tk/img/SakhiyeeeFromThrissurPooram.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Samajavaragamana Female Cover By Shreya Ghoshal.mp3",
                            title: "Samajavaragamana Female Cover By Shreya Ghoshal :: Naasongs.one",
                            artist: "Shreya Ghoshal",
                            imgSrc: "https://light.protidhwani.tk/img/SamajavaragamanaFemaleCoverByShreyaGhoshalNaasongs.one.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Sanam Re - Phir Mohabbat.mp3",
                            title: "Sanam Re-Phir Mohabbat (Pagalworld.io)",
                            artist: "Tulsi Kumar, Benny Dayal (Pagalworld.io)",
                            imgSrc: "https://light.protidhwani.tk/img/SanamRePhirMohabbatPagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Seeti Maar-SenSongsMp3.Co.mp3",
                            title: "Seeti Maar-SenSongsMp3.Co",
                            artist: "Jaspreet Jasz, Rita",
                            imgSrc: "https://light.protidhwani.tk/img/SeetiMaarSenSongsMp3.Co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Senimai_-_Neel_Akash(Asomi.In).mp3",
                            title: "Senimai - Neel Akash-(SpicyMP3.Co)",
                            artist: "Neel Akash",
                            imgSrc: "https://light.protidhwani.tk/img/SenimaiNeelAkashSpicyMP3.Co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/She Dont Know - Millind Gaba.mp3",
                            title: "She Dont Know - Pagalworld.io",
                            artist: "Millind Gaba - Pagalworld.io",
                            imgSrc: "https://light.protidhwani.tk/img/SheDontKnowPagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Shiva Thaandavam - Isaimini.Audio.mp3",
                            title: "Shiva Thaandavam - Isaimini.Audio",
                            artist: "S.P Balasubramanian - Isaimini.Audio",
                            imgSrc: "https://light.protidhwani.tk/img/ShivaThaandavamIsaimini.Audio.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Singappenney Full Song-Masstamilan.in.mp3",
                            title: "Singappenney-Masstamilan.in",
                            artist: "A.R. Rahman, Shashaa Tirupati",
                            imgSrc: "https://light.protidhwani.tk/img/SingappenneyMasstamilan.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Sita kalyana vaibhogame.mp3",
                            title: "Sita kalyana vaibhogame",
                            artist: "Kavya Ajit",
                            imgSrc: "https://light.protidhwani.tk/img/Sitakalyanavaibhogame.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Slow Motion - Bharat.mp3",
                            title: "Slow Motion (Pagalworld.io)",
                            artist: "Shreya Ghoshal, Nakash Aziz (Pagalworld.io)",
                            imgSrc: "https://light.protidhwani.tk/img/SlowMotionPagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Sneham Cherum Neram.mp3",
                            title: "Sneham Cherum Neram",
                            artist: "Rinu Razak,Hesham Abdul Wahab",
                            imgSrc: "https://light.protidhwani.tk/img/SnehamCherumNeram.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Sukhibhava Annaru-Naasongs.Audio.mp3",
                            title: "Sukhibhava Annaru--Naasongs.Audio",
                            artist: "Shreya Ghoshal, Rohith",
                            imgSrc: "https://light.protidhwani.tk/img/SukhibhavaAnnaruNaasongs.Audio.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Suna Kancha.mp3",
                            title: "Suna Kancha",
                            artist: "Trishna Gurung",
                            imgSrc: "https://light.protidhwani.tk/img/SunaKancha.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Swagatham Krishna.mp3",
                            title: "Swagatham Krishna",
                            artist: "Niranjana Ramanan",
                            imgSrc: "https://light.protidhwani.tk/img/SwagathamKrishna.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/TORALI Party Version _ Achurjya Borpatra _ Aimee B(MP3_160K).mp3",
                            title: "TORALI Party Version | Achurjya Borpatra | Aimee B",
                            artist: "Achurjya Borpatra Official",
                            imgSrc: "https://light.protidhwani.tk/img/TORALIPartyVersionAchurjyaBorpatraAimeeB.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Taakey Olpo Kachhe Dakchhi.mp3",
                            title: "Taakey Olpo Kachhe Dakchhi",
                            artist: "Mahtim Shakib",
                            imgSrc: "https://light.protidhwani.tk/img/TaakeyOlpoKachheDakchhi.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Tauba Tauba.mp3",
                            title: "Tauba Tauba",
                            artist: "M.M.Manasi,Nakash Aziz",
                            imgSrc: "https://light.protidhwani.tk/img/TaubaTauba.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Te-Amo-Duet (SongsMp3.Com).mp3",
                            title: "Te Amo Duet (SongsMp3.Com)",
                            artist: "www.SongsMP3.Com, ",
                            imgSrc: "https://light.protidhwani.tk/img/TeAmoDuetSongsMp3.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Tera Yaar Hoon Main-Yaar Mod Do.mp3",
                            title: "Tera Yaar Hoon Main-Yaar Mod Do (Pagalworld.Live)",
                            artist: "Neha Kakkar , Millind Gaba (Pagalworld.Live)",
                            imgSrc: "https://light.protidhwani.tk/img/TeraYaarHoonMainYaarModDoPagalworld.Live.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Tere Bina.mp3",
                            title: "Tere Bina",
                            artist: "A.R. Rahman,Chinmayi,Murtuza Khan,Qadir Khan",
                            imgSrc: "https://light.protidhwani.tk/img/TereBina.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Thaarame Thaarame.mp3",
                            title: "Thaarame Thaarame",
                            artist: "Sid Sriram,Ghibran",
                            imgSrc: "https://light.protidhwani.tk/img/ThaarameThaarame.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Thalli-Pogathey.mp3",
                            title: "Thalli Pogathey - MassTamilan.com",
                            artist: "Sid Sriram, Aaryan Dinesh Kanagaratnam, Aparna Narayanan - MassTamilan.com",
                            imgSrc: "https://light.protidhwani.tk/img/ThalliPogatheyMassTamilan.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Thathaka-Theithare-MassTamilan.fm.mp3",
                            title: "Thathaka Theithare - MassTamilan.fm",
                            artist: "Prithviraj Sukumaran, Hesham Abdul Wahab - MassTamilan.fm",
                            imgSrc: "https://light.protidhwani.tk/img/ThathakaTheithareMassTamilan.fm.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/The Greatest Showman Cast - The Greatest Show (Off(MP3_160K) (1).mp3",
                            title: "The Greatest Showman Cast - The Greatest Show (Off",
                            artist: "Atlantic Records",
                            imgSrc: "https://light.protidhwani.tk/img/TheGreatestShowmanCastTheGreatestShowOff.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Timro Akhaima Gajal.mp3",
                            title: "Timro Akhaima Gajal",
                            artist: "Benisha Paudel,Nima Raya",
                            imgSrc: "https://light.protidhwani.tk/img/TimroAkhaimaGajal.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Tujhko Jo Paaya (Crook) - Mohit Chauhan - 320Kbps.mp3",
                            title: "Tujhko Jo Paaya - PagalWorld.cool",
                            artist: "Mohit Chauhan (PagalWorld.me)",
                            imgSrc: "https://light.protidhwani.tk/img/TujhkoJoPaayaPagalWorld.cool.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Tum Jo Aaye (Reprise) - www.Mastiway.com.mp3",
                            title: "Tum Jo Aaye (Reprise) - www.Mastiway.com pagalworld.com",
                            artist: "pagalworld.com",
                            imgSrc: "https://light.protidhwani.tk/img/TumJoAayeReprisewww.Mastiway.compagalworld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Tum Jo Aaye - www.Mastiway.com.mp3",
                            title: "Tum Jo Aaye - www.Mastiway.com pagalworld.com",
                            artist: "pagalworld.com",
                            imgSrc: "https://light.protidhwani.tk/img/TumJoAayewww.Mastiway.compagalworld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Tumhe Apna Banane Ka-Chaand Chhupa.mp3",
                            title: "Tumhe Apna Banane Ka-Chaand Chhupa (Pagalworld.io)",
                            artist: "Neeti Mohan, Vishal Dadlani (Pagalworld.io)",
                            imgSrc: "https://light.protidhwani.tk/img/TumheApnaBananeKaChaandChhupaPagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Tumi Aru Moi (From Sri Raghupati).mp3",
                            title: "Tumi Aru Moi (From Sri Raghupati)",
                            artist: "Zubeen Garg,Deeplina Deka,Pranoy Dutta",
                            imgSrc: "https://light.protidhwani.tk/img/TumiAruMoiFromSriRaghupati.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Un-Paarvayil.mp3",
                            title: "Un Paarvayil - MassTamilan.com",
                            artist: "Anirudh, Vivek Siva - MassTamilan.com",
                            imgSrc: "https://light.protidhwani.tk/img/UnPaarvayilMassTamilan.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Unakaga - SenSongsMp3.Co.mp3",
                            title: "Unakaga :: SenSongsMp3.Co",
                            artist: "Sreekanth Hariharan, Madhura Dhara Talluri",
                            imgSrc: "https://light.protidhwani.tk/img/UnakagaSenSongsMp3.Co.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Undiporaadhey---Sad-Version-MassTamilan.io.mp3",
                            title: "Undiporaadhey - Sad Version - MassTamilan.io",
                            artist: "Sid Sriram - MassTamilan.io",
                            imgSrc: "https://light.protidhwani.tk/img/UndiporaadheySadVersionMassTamilan.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Uri Jai.mp3",
                            title: "Uri Jai",
                            artist: "Tavreed,Tanmoy Saikia",
                            imgSrc: "https://light.protidhwani.tk/img/UriJai.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Uyire.mp3",
                            title: "Uyire",
                            artist: "Sid Sriram,Ankit Menon",
                            imgSrc: "https://light.protidhwani.tk/img/Uyire.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Uyirini Uyire - Isaimini.Audio.mp3",
                            title: "Uyirini Uyire - Isaimini.Audio",
                            artist: "Saindhavi,Sathya Prakash & G.V Prakash Kumar - Isaimini.Audio",
                            imgSrc: "https://light.protidhwani.tk/img/UyiriniUyireIsaimini.Audio.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Vaseegara.mp3",
                            title: "Vaseegara",
                            artist: "Bombay Jayashri",
                            imgSrc: "https://light.protidhwani.tk/img/Vaseegara.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ve Maahi - Kesari.mp3",
                            title: "Ve Maahi - Pagalworld.io",
                            artist: "Arijit Singh, Asees Kaur (Pagalworld.io)",
                            imgSrc: "https://light.protidhwani.tk/img/VeMaahiPagalworld.io.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Vilambara Idaiveli - From Imaikkaa Nodigal.mp3",
                            title: "Vilambara Idaiveli - From Imaikkaa Nodigal",
                            artist: "Hiphop Tamizha,Christopher Stanley,Sudarshan Ashok,Srinisha Jayaseelan",
                            imgSrc: "https://light.protidhwani.tk/img/VilambaraIdaiveliFromImaikkaaNodigal.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Will You Be There - Isaimini.Audio.mp3",
                            title: "Will You Be There - Isaimini.Audio",
                            artist: "Alyssa, Marie - Isaimini.Audio",
                            imgSrc: "https://light.protidhwani.tk/img/WillYouBeThereIsaimini.Audio.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ya Ali - Gangster 320Kbps.mp3",
                            title: "Ya Ali - PagalWorld.info",
                            artist: "Zubeen - PagalWorld.click",
                            imgSrc: "https://light.protidhwani.tk/img/YaAliPagalWorld.info.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Ya Ali 128 Kbps.mp3",
                            title: "Ya Ali",
                            artist: "Pritam, Zubeen Garg",
                            imgSrc: "https://light.protidhwani.tk/img/YaAli.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Yaar Azhaippadhu.mp3",
                            title: "Yaar Azhaippadhu",
                            artist: "Ghibran,Sid Sriram",
                            imgSrc: "https://light.protidhwani.tk/img/YaarAzhaippadhu.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Yaaro Naanu.mp3",
                            title: "Yaaro Naanu [KannadaMasti.Com]",
                            artist: "Shreya Ghoshal",
                            imgSrc: "https://light.protidhwani.tk/img/YaaroNaanuKannadaMasti.Com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Yaen Ennai Pirindhaai - Male.mp3",
                            title: "Yaen Ennai Pirindhaai - Male",
                            artist: "Sid Sriram,Dhruv Vikram,Banita Sandhu",
                            imgSrc: "https://light.protidhwani.tk/img/YaenEnnaiPirindhaaiMale.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Yaen Ennai Pirindhaai-Masstamilan.in.mp3",
                            title: "Yaen Ennai Pirindhaai-Masstamilan.in",
                            artist: "Sid Sriram",
                            imgSrc: "https://light.protidhwani.tk/img/YaenEnnaiPirindhaaiMasstamilan.in.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/Zara Si Dosti - Happy Bhag Jayegi (Arijit Singh).mp3",
                            title: "Zara Si Dosti - PagalWorld.com",
                            artist: "Arijit Singh - PagalWorld.com",
                            imgSrc: "https://light.protidhwani.tk/img/ZaraSiDostiPagalWorld.com.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[SPOTIFY-DOWNLOADER.COM] ALWAYS.mp3",
                            title: "ALWAYS",
                            artist: "Yoon Mirae",
                            imgSrc: "https://light.protidhwani.tk/img/ALWAYS.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[SPOTIFY-DOWNLOADER.COM] By My Side.mp3",
                            title: "By My Side",
                            artist: "SG Wannabe",
                            imgSrc: "https://light.protidhwani.tk/img/ByMySide.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[SPOTIFY-DOWNLOADER.COM] Descendants Of The Sun (Original Television Soundtrack) Pt.6 - Talk Love.mp3",
                            title: "Descendants Of The Sun (Original Television Soundtrack) Pt.6 - Talk Love",
                            artist: "K.Will",
                            imgSrc: "https://light.protidhwani.tk/img/DescendantsOfTheSunOriginalTelevisionSoundtrackPt.6TalkLove.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[SPOTIFY-DOWNLOADER.COM] Everytime.mp3",
                            title: "Everytime",
                            artist: "CHEN,Punch",
                            imgSrc: "https://light.protidhwani.tk/img/Everytime.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[SPOTIFY-DOWNLOADER.COM] How Can I Love You.mp3",
                            title: "How Can I Love You",
                            artist: "XIA",
                            imgSrc: "https://light.protidhwani.tk/img/HowCanILoveYou.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[SPOTIFY-DOWNLOADER.COM] Once Again.mp3",
                            title: "Once Again",
                            artist: "Mad Clown,Kim Na Young",
                            imgSrc: "https://light.protidhwani.tk/img/OnceAgain.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[SPOTIFY-DOWNLOADER.COM] This Love.mp3",
                            title: "This Love",
                            artist: "DAVICHI",
                            imgSrc: "https://light.protidhwani.tk/img/ThisLove.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[SPOTIFY-DOWNLOADER.COM] With You.mp3",
                            title: "With You",
                            artist: "Lyn",
                            imgSrc: "https://light.protidhwani.tk/img/WithYou.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[SPOTIFY-DOWNLOADER.COM] You Are My Everything.mp3",
                            title: "You Are My Everything",
                            artist: "Gummy",
                            imgSrc: "https://light.protidhwani.tk/img/YouAreMyEverything.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 01 - Chirunama Thana Chirunama.mp3",
                            title: "[iSongs.info] 01 - Chirunama Thana Chirunama",
                            artist: "Yazin Nizar, Kareemulla",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info01ChirunamaThanaChirunama.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 01 - Edho Edho Bagunde.mp3",
                            title: "[iSongs.info] 01 - Edho Edho Bagunde",
                            artist: "Rahul Nambiar",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info01EdhoEdhoBagunde.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 01 - Hey Baby.mp3",
                            title: "[iSongs.info] 01 - Hey Baby",
                            artist: "GV Prakash Kumar, Aishwarya, Jai Srinivas",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info01HeyBaby.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 01 - Kalla Boli.mp3",
                            title: "[iSongs.info] 01 - Kalla Boli",
                            artist: "Ghibran, K.G.Ranjith",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info01KallaBoli.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 01 - Paadukuntu.mp3",
                            title: "[iSongs.info] 01 - Paadukuntu",
                            artist: "Mano, Sai Charan",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info01Paadukuntu.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 01 - Soodu Soodu.mp3",
                            title: "[iSongs.info] 01 - Soodu Soodu",
                            artist: "Dhanunjay, Ramya Behara",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info01SooduSoodu.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 02 - Ayyare.mp3",
                            title: "[iSongs.info] 02 - Ayyare",
                            artist: "Shakthisree Gopalan",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info02Ayyare.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 02 - Ciciliya Ciciliya.mp3",
                            title: "[iSongs.info] 02 - Ciciliya Ciciliya",
                            artist: "Shakthi Shree, Haricharan",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info02CiciliyaCiciliya.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 02 - Kadalalle.mp3",
                            title: "[iSongs.info] 02 - Kadalalle",
                            artist: "Sid Sriram, Aishwarya Ravichandran, Justin Prabhakaran ",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info02Kadalalle.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 02 - Priyathama.mp3",
                            title: "[iSongs.info] 02 - Priyathama",
                            artist: "Sid Sriram",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info02Priyathama.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 03 - Challaga.mp3",
                            title: "[iSongs.info] 03 - Challaga",
                            artist: "Rahul Nambiyar, Megha, Clinton",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info03Challaga.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 03 - Gira Gira Gira.mp3",
                            title: "[iSongs.info] 03 - Gira Gira Gira",
                            artist: "Gowtham Bharadwaj, Justin Prabhakaran, Yamini Ghantasala",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info03GiraGiraGira.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 03 - Haali Haali.mp3",
                            title: "[iSongs.info] 03 - Haali Haali",
                            artist: "Brijesh Tripati Sandilya, Harini, Sunitha Upadrashta",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info03HaaliHaali.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 03 - Panchadhara Bomma.mp3",
                            title: "[iSongs.info] 03 - Panchadhara Bomma",
                            artist: "Anooj Guruwara, Reeta",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info03PanchadharaBomma.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 03 - Tauba Tauba.mp3",
                            title: "[iSongs.info] 03 - Tauba Tauba",
                            artist: "M. M. Manasi, Nakaash Aziz",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info03TaubaTauba.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 03 - Yegirenay Yegirenay.mp3",
                            title: "[iSongs.info] 03 - Yegirenay Yegirenay",
                            artist: "Ramya Behara, Abhay Jodhpurkar",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info03YegirenayYegirenay.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 04 - Achcham Telugandham (1).mp3",
                            title: "[iSongs.info] 04 - Achcham Telugandham",
                            artist: "D. Sathyaprakash, Christopher Stanley, Pravin Saivi",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info04AchchamTelugandham.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 04 - Achcham Telugandham.mp3",
                            title: "[iSongs.info] 04 - Achcham Telugandham",
                            artist: "D. Sathyaprakash, Christopher Stanley, Pravin Saivi",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info04AchchamTelugandham.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 04 - He s My Hero.mp3",
                            title: "[iSongs.info] 04 - He s My Hero",
                            artist: "Maalavika Manoj",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info04HesMyHero.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 04 - Nee Valle.mp3",
                            title: "[iSongs.info] 04 - Nee Valle",
                            artist: "Vandana Srinivasan",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info04NeeValle.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 04 - Nuvvele.mp3",
                            title: "[iSongs.info] 04 - Nuvvele",
                            artist: "Shweta Mohan",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info04Nuvvele.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 05 - Jhoomore Jhoomore.mp3",
                            title: "[iSongs.info] 05 - Jhoomore Jhoomore",
                            artist: "Nakash Aziz, Mohana Bhogaraju, Aditya Iyengar, Anurag Kulkarni",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info05JhoomoreJhoomore.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 05 - Oday Oday.mp3",
                            title: "[iSongs.info] 05 - Oday Oday",
                            artist: "Vijay Prakash, Ranina Reddy, Salmali Kholgade, Sasha",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info05OdayOday.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 05 - Wi Wi Wi Wi Wifi.mp3",
                            title: "[iSongs.info] 05 - Wi Wi Wi Wi Wifi",
                            artist: "Karthik, Nikhita Gandhi, Christopher Stanley",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info05WiWiWiWiWifi.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 06 - Nannaku Prematho.mp3",
                            title: "[iSongs.info] 06 - Nannaku Prematho",
                            artist: "DSP, Sagar",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info06NannakuPrematho.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 06 - Vinave Vinave.mp3",
                            title: "[iSongs.info] 06 - Vinave Vinave",
                            artist: "G.V.Prakash Kumar, Shakthisree Gopalan",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info06VinaveVinave.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 07 - A Love Life.mp3",
                            title: "[iSongs.info] 07 - A Love Life",
                            artist: "G.V.Prakash Kumar, Navin Iyer, Chennai Symphony",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info07ALoveLife.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info] 07 - O Kalala Kathala.mp3",
                            title: "[iSongs.info] 07 - O Kalala Kathala",
                            artist: "Sathya Prakash, Chinmayi Sripaada",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info07OKalalaKathala.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info]%2001%20-%20Chirunama%20Thana%20Chirunama.mp3",
                            title: "[iSongs.info] 01 - Chirunama Thana Chirunama",
                            artist: "Yazin Nizar, Kareemulla",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info01ChirunamaThanaChirunama.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info]%2001%20-%20Kalla%20Boli.mp3",
                            title: "[iSongs.info] 01 - Kalla Boli",
                            artist: "Ghibran, K.G.Ranjith",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info01KallaBoli.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info]%2001%20-%20Paadukuntu.mp3",
                            title: "[iSongs.info] 01 - Paadukuntu",
                            artist: "Mano, Sai Charan",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info01Paadukuntu.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info]%2002%20-%20Ciciliya%20Ciciliya.mp3",
                            title: "[iSongs.info] 02 - Ciciliya Ciciliya",
                            artist: "Shakthi Shree, Haricharan",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info02CiciliyaCiciliya.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/[iSongs.info]%2003%20-%20Haali%20Haali.mp3",
                            title: "[iSongs.info] 03 - Haali Haali",
                            artist: "Brijesh Tripati Sandilya, Harini, Sunitha Upadrashta",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info03HaaliHaali.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/iSongs.info  02 - Jabilli.mp3",
                            title: "[iSongs.info] 02 - Jabilli",
                            artist: "Ranjith",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info02Jabilli.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/iSongs.info  02 - Laage Laage.mp3",
                            title: "[iSongs.info] 02 - Laage Laage",
                            artist: "Nakash Aziz",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info02LaageLaage.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/iSongs.info  02 - Musi Musi Navvula.mp3",
                            title: "[iSongs.info] 02 - Musi Musi Navvula",
                            artist: "Aalap Raju, Swetha Menon, NSK Ramya, Karthik",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info02MusiMusiNavvula.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/iSongs.info  02 - Tring Tring.mp3",
                            title: "[iSongs.info] 02 - Tring Tring",
                            artist: "Jaspreet Jasz, Ranina Reddy",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info02TringTring.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/iSongs.info  03 - Aanandam.mp3",
                            title: "[iSongs.info] 03 - Aanandam",
                            artist: "Anirudh Ravichander, Shashaa Tirupati, D. Sathyaprakash",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info03Aanandam.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/iSongs.info  03 - O Lailaa.mp3",
                            title: "[iSongs.info] 03 - O Lailaa",
                            artist: "Rahul, Chorus",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info03OLailaa.jpg",
                        },{
                            songSrc:"https://cdn.protidhwani.tk/song/iSongs.info  05 - Swing Zara.mp3",
                            title: "[iSongs.info] 05 - Swing Zara",
                            artist: "Neha Bhasin, Dsp",
                            imgSrc: "https://light.protidhwani.tk/img/iSongs.info05SwingZara.jpg",
                        },];

export default songs;